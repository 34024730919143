<template>
  <div id="container">
    <strong>{{ name }}</strong>
    <p>
      Explore
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://ionicframework.com/docs/components"
        >UI Components</a
      >
    </p>
    <template v-if="name == 'Tab 1 page'">
      <p>
        <small>{{ store.state.auth.token }}</small>
      </p>
    </template>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "ExploreContainer",
  props: {
    name: String
  },
  setup() {
    return {
      store
    };
  }
};
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
